<template>
  <div class="pls--page-header">
    <v-spacer />
    <v-btn
      class="pls--page-header-btn-right"
      color="primary"
      @click="openDialog"
    >
      <v-icon left>
        $iconify_plus-circle-outlined
      </v-icon>Создать отправку
    </v-btn>
    <v-btn
      class="pls--page-header-btn-right"
      color="primary"
      @click="refreshTable"
    >
      <v-icon left>
        $iconify_feather-refresh-ccw
      </v-icon>Обновить
    </v-btn>
    <base-drawer-dialog
      v-model="showDialog"
      :width="580"
      title="Создание отправки"
      class="drawer-sendings-create"
    >
      <v-form
        ref="form"
        class="form-create"
      >
        <BaseMasterFieldBlock
          class="mt-0"
          title="Тип получателя"
        >
          <template v-slot:input>
            <v-select
              v-model="form.recipient_type"
              :rules="rules.select"
              :items="recipientTypes"
              placeholder="Выберите значение из списка"
              outlined
              clearable
            />
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          v-if="form.recipient_type === 'segments' || form.recipient_type === 'clients'"
          class="mt-0"
          title="Получатели:"
        >
          <template v-slot:input>
            <account-select
              v-if="form.recipient_type === 'clients'"
              v-model="form.recipients"
              :program-id="programId"
              :rules="rules.selectMultiple"
              multiple
            />
            <segment-select
              v-if="form.recipient_type === 'segments'"
              v-model="form.recipients"
              class="field-client-segment"
              :rules="rules.selectMultiple"
              multiple
              clearable
              chips
              hide-selected
              :hide-details="false"
            />
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          class="mt-0"
          title="Дата отправки"
        >
          <template v-slot:input>
            <v-radio-group
              v-model="form.dateMode"
              class="mt-0"
              hide-details
              :rules="rules.radioGroup"
              row
            >
              <v-radio
                v-for="n in 2"
                :key="n"
                :label="n == 1 ? 'Сразу' : 'Выбрать дату' "
                :value="n"
              />
            </v-radio-group>
            <div class="field-date">
              <base-datetime-picker-field
                v-if="form.dateMode == 2"
                v-model="form.start_at"
                class="field-date"
                placeholder="Действует с"
                :display-format="$config.date.DATETIME_FORMAT_MIN2"
                :rules="rules.dateTextField"
                clearable
                :use-seconds="false"
                :date-picker-props="{ min: new Date().toISOString() }"
              />
            </div>
          </template>
        </BaseMasterFieldBlock>
        <div class="controls">
          <v-btn
            color="primary"
            class="btn-create"
            @click="clickCreateSending"
          >
            <iconify-icon
              class="icon-plus"
              icon="plus-circle-outlined"
              height="21"
            />
            Создать
          </v-btn>
        </div>
      </v-form>
    </base-drawer-dialog>
  </div>
</template>

<script>
  import AccountSelect from '@/components/ModelSelect/AccountSelect'
  import SegmentSelect from '@/components/ModelSelect/SegmentSelect'
  import { trimLR } from '@/utils'
  import { mapActions } from 'vuex'

  export default {
    components: {
      AccountSelect,
      SegmentSelect,
    },
    props: {
      tableOptions: {
        type: Object,
        default: function () {
          return {
            page: 1,
            itemsPerPage: 10,
          }
        },
      },
    },
    data () {
      return {
        internalTableOptions: {},
        showDialog: false,
        loadingCreateSending: false,
        form: {
          recipient_type: '',
          recipients: [],
          dateMode: 1,
          start_at: '',
        },
        recipientTypes: [
          { text: 'Все', value: 'all' },
          { text: 'Сегменты', value: 'segments' },
          { text: 'Клиенты', value: 'clients' },
        ],
        rules: {
          select: [
            v => Boolean(!!v && trimLR(v).length) || 'Обязательно для заполнения',
          ],
          selectMultiple: [
            v => Boolean(v && v.length) || 'Обязательно для заполнения',
          ],
          dateTextField: [
            v => Boolean(!!v && v.length) || 'Обязательно для заполнения',
          ],
          radioGroup: [
            v => !!v || 'Выберите одно из значений',
          ],
        },
      }
    },
    computed: {
      programId () {
        return this.$store.getters.programId
      },
    },
    watch: {
      'form.recipient_type' (v) {
        this.$set(this.form, 'recipients', [])
      },
      internalTableOptions: {
        deep: true,
        handler (v) {
          this.$emit('update:tableOptions', v)
        },
      },
      tableOptions: {
        deep: true,
        handler (v) {
          this.internalTableOptions = v
        },
      },
    },
    mounted () {
      if (this.$router.currentRoute.hash === '#dialog') this.showDialog = true
    },
    inject: ['notificationId'],
    methods: {
      ...mapActions({
        fetchCreate: 'company/sendings/create',
        fetchList: 'company/sendings/list',
      }),
      async openDialog () {
        this.$set(this, 'form', {
          recipient_type: '',
          recipients: [],
          dateMode: 1,
          start_at: '',
        })
        this.showDialog = true
        await this.$nextTick()
        this.$refs.form.resetValidation()
      },
      async refreshTable () {
        await this.fetchList({
          notificationId: this.notificationId,
          offset: this.getOffset(this.internalTableOptions.page, this.internalTableOptions.itemsPerPage),
          limit: this.internalTableOptions.itemsPerPage,
        })
      },
      async clickCreateSending () {
        if (!this.$refs.form.validate()) {
          this.$notify({
            type: 'error',
            title: 'Отправки',
            text: 'Ошибки валидации',
          })
          return
        }

        const data = {
          notification_id: this.notificationId,
          send_to_all: this.form.recipient_type === 'all',
          start_at: this.form.dateMode === 1 ? null : this.form.start_at,
          recipients: this.form.recipients.map(v => {
            if (this.form.recipient_type === 'clients') {
              return { id: v, type: 'accounts' }
            } else if (this.form.recipient_type === 'segments') {
              return { id: v, type: 'program_client_segments' }
            }
          }),
        }

        this.loadingCreateSending = true
        await this.fetchCreate(data)
        this.loadingCreateSending = false

        this.showDialog = false
      },
      getOffset (page, limit) {
        return (page * limit) - limit
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/pls/page/_page.scss";

::v-deep {
  .drawer-sendings-create {
    z-index: 999;
    .controls {
      .btn-create {
        .icon-plus {
          margin-right: 8px;
        }
      }
    }
  }
}

.pls--page-header {
  margin-bottom: 14px;
}

.form-create {
  .field-date {
    margin-top: 34px;
  }
  .field-client-segment {
    margin-bottom: 30px;
  }
}
</style>
