var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pls--page-table"},[_c('base-table',{staticClass:"table-sendings",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"search":_vm.search,"options":_vm.internalTableOptions,"total-count":_vm.sendingsTotal,"server-items-length":_vm.sendingsTotal,"sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc,"word-operations":['отправка', 'отправки', 'отправок']},on:{"update:options":function($event){_vm.internalTableOptions=$event},"update:sortBy":function($event){_vm.tableSortBy=$event},"update:sort-by":function($event){_vm.tableSortBy=$event},"update:sortDesc":function($event){_vm.tableSortDesc=$event},"update:sort-desc":function($event){_vm.tableSortDesc=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.recipient_type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(_vm.getRecipientType(item))+" ")])]}},{key:"item.recipients",fn:function(ref){
var item = ref.item;
return [_vm._l((item.segment_recipients),function(segment){return _c('segment-chip',{key:segment.id,staticStyle:{"margin":"5px"},attrs:{"name":segment.name,"color":segment.color,"max-len":40,"chip":""}})}),_vm._l((_vm.transAccountIntoUser(item.accountview_recipients)),function(account){return _c('user-column',{key:account.id,staticStyle:{"margin":"5px"},attrs:{"user":account,"show-last-activity":false}})})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(_vm.langStatus[item.status])+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.created_at}})]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.start_at}})]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.finished_at}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.action}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getItemActions(item)),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                'body-s-medium' : true,
                'neutral-500--text':true,
              }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }