<template>
  <v-skeleton-loader
    :loading="loading"
    :style="{height: '100%', width: '100%'}"
    type="card-heading, image@3"
  >
    <base-top-menu
      :show-action="false"
      cancel-button-text="Закрыть"
      @cancelbutton="$router.push({ name: 'Mailing' })"
    >
      <template v-slot:header>
        <div class="body-l-semibold neutral-900--text mb-0">
          Отправки: {{ template.name }}
        </div>
      </template>
      <div style="width: 100%">
        <sendings-header
          :search.sync="search"
          :table-options.sync="tableOptions"
        />
        <sendings-table
          :search="search"
          :table-options.sync="tableOptions"
        />
      </div>
    </base-top-menu>
  </v-skeleton-loader>
</template>

<script>
  import SendingsHeader from './Header'
  import SendingsTable from './Table'

  export default {
    components: {
      SendingsHeader,
      SendingsTable,
    },
    props: {
      notificationId: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        search: '',
        pageList: [
          { id: '#', name: 'Отправки', route: { hash: '' } },
        ],
        loading: false,
        tableOptions: {
          page: 1,
          itemsPerPage: 10,
        },
      }
    },
    computed: {
      template () {
        return this.$store.getters['company/notifications/template']
      },
    },
    provide () {
      return {
        notificationId: this.notificationId,
      }
    },
    async mounted () {
      if (!this.notificationId || isNaN(this.notificationId)) {
        this.$router.back()
      } else {
        this.loading = true
        await this.$store.dispatch('company/notifications/read', { id: this.notificationId })
        if (this.template.moderation_status !== 'ACCEPT') {
          this.$router.back()
        } else {
          this.loading = false
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/pls/page/_page.scss";
</style>
