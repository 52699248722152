<template>
  <div class="pls--page-table">
    <base-table
      class="table-sendings"
      :headers="tableHeaders"
      :items="tableItems"
      :search="search"
      :options.sync="internalTableOptions"
      :total-count="sendingsTotal"
      :server-items-length="sendingsTotal"
      :sort-by.sync="tableSortBy"
      :sort-desc.sync="tableSortDesc"
      :word-operations="['отправка', 'отправки', 'отправок']"
    >
      <template v-slot:[`item.id`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ item.id }}
        </p>
      </template>
      <template v-slot:[`item.recipient_type`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ getRecipientType(item) }}
        </p>
      </template>
      <template v-slot:[`item.recipients`]="{ item }">
        <segment-chip
          v-for="segment in item.segment_recipients"
          :key="segment.id"
          :name="segment.name"
          :color="segment.color"
          :max-len="40"
          style="margin: 5px;"
          chip
        />
        <user-column
          v-for="account in transAccountIntoUser(item.accountview_recipients)"
          :key="account.id"
          :user="account"
          style="margin: 5px;"
          :show-last-activity="false"
        />
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ langStatus[item.status] }}
        </p>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <date-column :value="item.created_at" />
      </template>
      <template v-slot:[`item.start_at`]="{ item }">
        <date-column :value="item.start_at" />
      </template>
      <template v-slot:[`item.finished_at`]="{ item }">
        <date-column :value="item.finished_at" />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              :loading="item.action"
              v-on="on"
            >
              <v-icon>$iconify_feather-more-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(menuItem, index) in getItemActions(item)"
              :key="index"
              @click="menuItem.action(item)"
            >
              <v-list-item-icon>
                <v-icon color="neutral-500">
                  {{ menuItem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class=" {
                  'body-s-medium' : true,
                  'neutral-500--text':true,
                }"
              >
                {{ menuItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import DateColumn from '@/components/colums/DateColumn'

  export default {
    components: {
      DateColumn,
      UserColumn: () => import('@/components/colums/UserColumn'),
      SegmentChip: () => import('@/components/segment/SegmentChip'),
    },
    props: {
      search: {
        type: String,
        default: '',
      },
      tableOptions: {
        type: Object,
        default: function () {
          return {
            page: 1,
            itemsPerPage: 10,
          }
        },
      },
    },
    data () {
      return {
        tableHeaders: [
          { text: 'ID', align: 'start', value: 'id', sortable: false },
          { text: 'Тип получателя', align: 'start', value: 'recipient_type', sortable: false },
          { text: 'Получатели', align: 'start', value: 'recipients', sortable: false },
          { text: 'Статус', align: 'start', value: 'status', sortable: false },
          { text: 'Дата создания', align: 'start', value: 'created_at', sortable: false },
          { text: 'Дата запуска', align: 'start', value: 'start_at', sortable: false },
          { text: 'Дата завершения', align: 'start', value: 'finished_at', sortable: false },
          { text: 'Результат', align: 'start', value: 'result', sortable: false },
          { text: 'Действия', align: 'end', value: 'actions', sortable: false },
        ],
        tableKey: 'SendingsTable',
        tableSortBy: 'id',
        tableSortDesc: true,
        internalTableOptions: {},
        langStatus: {
          created: 'Создана',
          queued: 'Ожидает',
          running: 'Выполняется',
          finished: 'Завершена',
          failed: 'Ошибка',
          canceled: 'Отменена',
        },
      }
    },
    inject: ['notificationId'],
    computed: {
      ...mapGetters({
        programId: 'programId',
        sendings: 'company/sendings/sendings',
        sending: 'company/sendings/sending',
        sendingsTotal: 'company/sendings/sendingsTotal',
      }),
      tableItems () {
        return this.sendings.map((job) => {
          job.result = ''
          if (job.output && job.output.error) {
            job.result = job.output.error
          } else if (job.output && job.status === 'finished') {
            job.result = `Отправлено: ${job.output.success}, Ошибки: ${job.output.failed}`
          }

          return job
        })
      },
    },
    watch: {
      async 'internalTableOptions.page' () {
        await this.fetchListWithParams()
      },
      async 'internalTableOptions.itemsPerPage' () {
        await this.fetchListWithParams()
      },
      internalTableOptions: {
        deep: true,
        handler (v) {
          this.$emit('update:tableOptions', v)
        },
      },
      tableOptions: {
        deep: true,
        handler (v) {
          this.internalTableOptions = v
        },
      },
    },
    async mounted () {
      await this.fetchListWithParams()
    },
    beforeDestroy () {
      this.$store.commit('company/sendings/SET_SENDINGS', [])
      this.$store.commit('company/sendings/SET_SENDINGS_TOTAL', 0)
    },
    methods: {
      ...mapActions({
        fetchList: 'company/sendings/list',
        fetchRun: 'company/sendings/run',
        fetchDelete: 'company/sendings/delete',
      }),
      getItemActions (item) {
        return [
          {
            icon: '$iconify_feather-send',
            title: 'Отправить снова',
            action: this.onSendAgain.bind(null),
            show: true,
          },
          {
            icon: '$iconify_feather-trash',
            title: 'Удалить',
            action: this.onDelete.bind(null, item),
            show: true,
          },
        ].filter(x => x.show)
      },
      transAccountIntoUser (accountviewRecipients) {
        return accountviewRecipients.map(avr => {
          if (!avr.name) avr.name = avr.client_name
          return avr
        })
      },
      async onSendAgain (item) {
        await this.fetchRun(item.id)
        await this.fetchListWithParams()
      },
      async onDelete (item) {
        await this.fetchDelete(item.id)
      },
      async fetchListWithParams () {
        await this.fetchList({
          notificationId: this.notificationId,
          offset: this.getOffset(this.internalTableOptions.page, this.internalTableOptions.itemsPerPage),
          limit: this.internalTableOptions.itemsPerPage,
        })
      },
      getRecipientType (item) {
        if (item.send_to_all) return 'Все'

        const types = []
        if (item.segment_recipients.length) types.push('Сегменты')
        if (item.accountview_recipients.length) types.push('Клиенты')

        return types.join(', ')
      },
      getOffset (page, limit) {
        return (page * limit) - limit
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/pls/page/_page.scss";

.table-sendings {
  & ::v-deep {
    tbody {
        tr {
          cursor: pointer;
      }
    }
  }
  margin-bottom: 34px;
}
</style>
